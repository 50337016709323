import { BeboerWithBoliger } from '../api/queries/beboereAndBoliger/domain';

import { boligOrderComparator } from './Bolig';
import { getFulltNavnOrEpost, Person, PersonWithOptionalId } from './Person';
import { Employee, isEmployee, Organization } from './Organization';
import { isOrganization, isPerson } from '../api/queries/beboereAndBoliger/useBeboereQuery';
import { isLeverandorContact, Leverandorkontakt } from './LeverandorForBoligselskap';
import { User } from '../api/queries/user/domain';
import { t } from 'i18next';

export type Beboer = Person | Organization;

export type BeboerType = 'personer' | 'organisasjoner';

export const returnBeboerType = (beboer: Beboer): BeboerType => {
  if (isPerson(beboer)) {
    return 'personer';
  } else if (isOrganization(beboer)) {
    return 'organisasjoner';
  } else if (isLeverandorContact(beboer)) {
    return 'personer';
  } else {
    throw new Error("Beboer does not match 'Person' or 'Organization' type.");
  }
};

export const returnPersonBeboere = (beboere: Beboer[]): Person[] =>
  // @ts-ignore
  beboere.filter((beboer) => isPerson(beboer));

export const returnOrgBeboere = (beboere: Beboer[]): Organization[] =>
  // @ts-ignore
  beboere.filter((beboer) => isOrganization(beboer));

export const beboerShortName = (beboer?: Beboer): string => {
  if (!beboer) {
    return '';
  } else if (isPerson(beboer)) {
    return beboer.fornavn ?? 'Ukjent';
  } else if (isOrganization(beboer)) {
    return beboer.name;
  } else {
    throw new Error("Beboer does not match 'Person' or 'Organization' type.");
  }
};

export const beboerFormalName = (beboer?: Beboer): string => {
  if (!beboer) {
    return '';
  } else if (isPerson(beboer)) {
    return beboer.etternavn ?? 'Ukjent';
  } else if (isOrganization(beboer)) {
    return beboer.name;
  } else {
    throw new Error("Beboer does not match 'Person' or 'Organization' type.");
  }
};

export const entityFullName = (
  entity: Beboer | Leverandorkontakt | Employee | PersonWithOptionalId | User | undefined
): string => {
  if (!entity) {
    return t('errorMessage.unknown');
  } else if (isPerson(entity)) {
    return getFulltNavnOrEpost(entity);
  } else if (isOrganization(entity)) {
    return entity.name;
  } else if (isLeverandorContact(entity) || isEmployee(entity)) {
    return `${entity.givenName} ${entity.familyName}`;
  } else {
    return `${entity.fornavn} ${entity.etternavn}`;
  }
};

export const entityEmail = (
  entity: Beboer | Leverandorkontakt | Employee | PersonWithOptionalId
): string | undefined => {
  if (isPerson(entity)) {
    return entity.epost;
  } else if (isOrganization(entity)) {
    return entity.businessContact?.email;
  } else if (isLeverandorContact(entity) || isEmployee(entity)) {
    return entity.email;
  } else {
    throw new Error("Beboer does not match 'Person' or 'Organization' type.");
  }
};

export const beboerEmail = (beboer?: Beboer): string | undefined => {
  if (!beboer) {
    return '';
  } else if (isPerson(beboer)) {
    return beboer.epost;
  } else if (isOrganization(beboer)) {
    return beboer.businessContact?.email;
  } else {
    throw new Error("Beboer does not match 'Person' or 'Organization' type.");
  }
};

export const beboerPhone = (beboer?: Beboer): string | undefined => {
  if (!beboer) {
    return '';
  } else if (isPerson(beboer)) {
    if (!beboer.mobil) {
      return '';
    } else {
      return beboer.completeMobile ?? `${beboer.mobilCountryCode ?? ''} ${beboer.mobil ?? ''}`;
    }
  } else if (isOrganization(beboer)) {
    if (!beboer.businessContact?.phone) {
      return '';
    } else {
      return (
        beboer.businessContact?.completePhone ??
        `${beboer.businessContact?.phoneCountryCode ?? ''} ${beboer.businessContact?.phone ?? ''}`
      );
    }
  } else {
    throw new Error("Beboer does not match 'Person' or 'Organization' type.");
  }
};

export const adresseDisplayText = (beboer: BeboerWithBoliger) => {
  const bolig =
    beboer.boliger.length > 0 ? beboer.boliger.sort(boligOrderComparator)[0] : undefined;

  if (isPerson(beboer)) {
    return beboer.gateadresse
      ? adresse(beboer.gateadresse, beboer.postnummer, beboer.poststed)
      : bolig
        ? adresse(bolig.gateadresse, bolig.postnummer, bolig.poststed)
        : null;
  } else if (isOrganization(beboer)) {
    return beboer.businessContact
      ? adresse(
          beboer.businessContact?.streetAdresse,
          beboer.businessContact?.zip,
          beboer.businessContact?.area
        )
      : beboer.businessAdresse
        ? adresse(
            beboer.businessAdresse?.streetAdresse,
            beboer.businessAdresse?.zip,
            beboer.businessAdresse?.area
          )
        : null;
  }
};

export const adresse = (gateadresse?: string, postnummer?: string, poststed?: string) =>
  gateadresse
    ? postnummer || poststed
      ? `${gateadresse}, ${postnummer ? postnummer + ' ' : ''} ${poststed ? poststed : ''}`
      : gateadresse
    : '';
