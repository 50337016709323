import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { Beboer } from '../../../domain/Beboer';
import { IdType } from '../../../domain/common';

import { fetchBeboere } from '../../beboerApi';
import { Person } from '../../../domain/Person';
import { Organization } from '../../../domain/Organization';

export const isPerson = (data: any): data is Person => {
  return 'isUser' in data;
};

export const isOrganization = (data: any): data is Organization => {
  return 'orgnr' in data;
};

export const beboereQryKey = (boligselskapId?: IdType) => ['beboere', boligselskapId];

export const useBeboereQuery = (selskapId?: string) => {
  const { boligselskapId } = useParams();

  return useQuery<Beboer[]>(
    beboereQryKey(selskapId ? parseInt(selskapId) : parseInt(boligselskapId!!)),
    () => fetchBeboere(selskapId ? parseInt(selskapId) : parseInt(boligselskapId!!)),
    {
      enabled:
        (!!boligselskapId && boligselskapId !== 'mine-boligselskaper') ||
        (!!selskapId && !isNaN(parseInt(selskapId))),
    }
  );
};
