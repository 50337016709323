import { useEffect, useState } from 'react';

// The breakpoint you get when using Tailwind's 'xl:' in CSS
const breakpointXl = 1280;
// The breakpoint you get when using Tailwind's '2xl:' in CSS
const breakpoint2xl = 1536;
// The breakpoint you get when using Tailwind's custom '3xl:' in CSS
const breakpoint3xl = 1800;

const getWindowWidth = () => window.innerWidth;

export const useScreenBreakpoints = () => {
  const [width, setWidth] = useState(getWindowWidth());

  useEffect(() => {
    function handleResize() {
      setWidth(getWindowWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isLargeScreen: width > breakpointXl,
    is2XLargeScreen: width > breakpoint2xl,
    is3XLargeScreen: width > breakpoint3xl,
  };
};
