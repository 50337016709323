import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './translations/en-US.json';
import nbTranslations from './translations/no-NB.json';
import esTranslations from './translations/es-ES.json';

const resources = {
  en: {
    translation: enTranslations,
  },
  nb: {
    translation: nbTranslations,
  },
  es: {
    translation: esTranslations,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resources,
    debug: false,
    fallbackLng: ['nb', 'en', 'es'],
    supportedLngs: ['nb', 'en', 'es'],
    interpolation: {
      escapeValue: false,
    },
  })
  .then();

export default i18n;
