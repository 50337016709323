import { Rolle } from '../api/queries/user/domain';

import { Bod } from './Bod';
import { Parkeringsplass } from './Parkeringsplass';
import { IdType, ISODateString, UrlType } from './common';
import { BeboerType } from './Beboer';

export const orgnrRegex = /^([0-9]{15}|[0-9]{9})$/;

export interface Organization {
  accountNumber?: string;
  boder?: Bod[];
  businessAdresse?: BusinessAddress;
  businessContact?: BusinessContact;
  classification?: string;
  country: string;
  employees: Employee[];
  historicRoles: Rolle[];
  id: IdType;
  logoURL?: UrlType;
  name: string;
  nationality: IdType;
  notat?: string;
  orgnr: string;
  parkeringsplasser?: Parkeringsplass[];
  roles: Rolle[];
  reservertEpost: boolean;
  reservertSMS: boolean;
  reservations: Record<string, OrgReservation>;
}

export interface OrgReservation {
  boligselskapId: number;
  reservertSMS: boolean;
  reservertEpost: boolean;
}

export interface boligContact {
  [key: string]: IdType[];
}

export interface Employee {
  boligContact: boligContact;
  boligselskapContact: IdType[];
  email?: string;
  familyName?: string;
  givenName?: string;
  id: IdType;
  mobile?: string;
  personId: IdType;
  start: ISODateString;
  stop?: ISODateString;
}

export interface BusinessAddress {
  area?: string;
  bnr?: string;
  fnr?: string;
  gnr?: string;
  knr?: string;
  number?: string;
  streetAdresse?: string;
  zip?: string;
}

export interface BusinessContact {
  area?: string;
  email?: string;
  phone?: string;
  phoneCountryCode?: string;
  completePhone?: string;
  invoicing?: InvoicingInfo;
  number?: string;
  streetAdresse?: string;
  zip?: string;
}

export interface AddOrgParams {
  beboerType: BeboerType;
  bodId?: IdType;
  boligId?: IdType;
  businessAdresse?: BusinessAddress;
  businessContact?: BusinessContact;
  classification: string;
  innflytningsdato: ISODateString;
  kontonummer?: string;
  name: string;
  nationality: IdType;
  orgnr: string;
  parkeringsplassId?: IdType;
  rolle: string;
  invoiceRecipient: boolean;
}

export interface EditOrgParams {
  beboerType: BeboerType;
  boligselskapId: IdType;
  beboerId: IdType;
  businessAdresse?: BusinessAddress;
  businessContact?: BusinessContact;
  name: string;
}

export interface InvoicingInfo {
  area?: string;
  email?: string;
  phone?: string;
  number?: string;
  streetAdresse?: string;
  zip?: string;
}

export const isEmployee = (data: any): data is Employee => {
  if (typeof data !== 'object' || data === null) return false;

  const requiredFields = [
    { key: 'id', type: 'stringOrNumber', isArray: false },
    { key: 'personId', type: 'stringOrNumber', isArray: false },
    { key: 'start', type: 'string', isArray: false },
  ];

  const optionalFields = [
    { key: 'email', type: 'string' },
    { key: 'familyName', type: 'string' },
    { key: 'givenName', type: 'string' },
    { key: 'mobile', type: 'string' },
    { key: 'stop', type: 'string' },
  ];

  const isValidType = (value: any, type: string, isArray = false): boolean => {
    if (isArray) {
      return Array.isArray(value) && value.every((item) => isValidType(item, type));
    }
    if (type === 'stringOrNumber') {
      return typeof value === 'string' || typeof value === 'number';
    }
    return typeof value === type;
  };

  return (
    requiredFields.every(
      (field) => field.key in data && isValidType(data[field.key], field.type)
    ) &&
    optionalFields.every(
      (field) => !(field.key in data) || isValidType(data[field.key], field.type)
    )
  );
};
